import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Alert, Submit } from '@sharecover-co/ui'
import Address from './../../utils/Address'
import { getQuote } from '../../actions/quote'
import { reduxForUpdateQuote } from '../../actions/quote'
import { Form } from 'formik'
import FormManager from './FormManager'
import schema from './address/addressFormValidator'
import AddtionalQuestions from './address/AddtionalQuestions'
import PreviousAddress from './address/PreviousAddress'
import get from 'lodash.get'
const AddressForm = (props) => {
    let condition =
        get(props, 'product.product.id', null) ===
            process.env.REACT_APP_LONG_HOMECOVER_PRODUCT_ID ||
        get(props, 'product.product.id', null) ===
            process.env.REACT_APP_SHORT_HOMECOVER_PRODUCT_ID
    return (
        <FormManager validationSchema={schema} {...props}>
            {({ status: { error }, values, handleChange, setFieldValue }) => (
                <Form>
                    {error && <Alert>{error}</Alert>}
                    <PreviousAddress />
                    <Address
                        id="address"
                        label="Mailing Address"
                        name="address.formatted"
                        required
                    />
                    <AddtionalQuestions
                        getSelectedCredits={props.getSelectedCredits}
                    />
                    {values.address.formatted &&
                        values.termsAndConditions &&
                        values.insuranceDeclined &&
                        values.criminalOffence &&
                        values.termsAndConditions === 'true' &&
                        values.insuranceDeclined === 'false' &&
                        values.criminalOffence === 'false' && (
                            <Submit label="Next" />
                        )}
                    {!condition && <Submit label="Next" />}
                </Form>
            )}
        </FormManager>
    )
}

AddressForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    ...state
})
const mapDispatchToProps = {
    getQuote,
    reduxForUpdateQuote
}
export default connect(mapStateToProps, mapDispatchToProps)(AddressForm)
