// Session
export const NEW_SESSION = 'NEW_SESSION'
export const ADD_DATA = 'ADD_DATA'

// Quote
export const GET_QUOTE_REQUEST = 'GET_QUOTE_REQUEST'
export const GET_QUOTE_SUCCESS = 'GET_QUOTE_SUCCESS'
export const GET_QUOTE_FAILED = 'GET_QUOTE_FAILED'

// Payment Method
export const LIST_PAYMENT_METHODS_REQUEST = 'LIST_PAYMENT_METHODS_REQUEST'
export const LIST_PAYMENT_METHODS_SUCCESS = 'LIST_PAYMENT_METHODS_SUCCESS'
export const LIST_PAYMENT_METHODS_FAILED = 'LIST_PAYMENT_METHODS_FAILED'

export const ADD_PAYMENT_METHOD_REQUEST = 'ADD_PAYMENT_METHOD_REQUEST'
export const ADD_PAYMENT_METHOD_SUCCESS = 'ADD_PAYMENT_METHOD_SUCCESS'
export const ADD_PAYMENT_METHOD_FAILED = 'ADD_PAYMENT_METHOD_FAILED'

// Policy
export const ADD_POLICY_REQUEST = 'ADD_POLICY_REQUEST'
export const ADD_POLICY_SUCCESS = 'ADD_POLICY_SUCCESS'
export const ADD_POLICY_FAILED = 'ADD_POLICY_FAILED'

// Profile
export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
export const GET_PROFILE_FAILED = 'GET_PROFILE_FAILED'

// Address
export const UPDATE_ADDRESS_REQUEST = 'UPDATE_ADDRESS_REQUEST'
export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS'
export const UPDATE_ADDRESS_FAILED = 'UPDATE_ADDRESS_FAILED'

// credit
export const GET_CREDIT_REQUEST = 'GET_CREDIT_REQUEST'
export const GET_CREDIT_SUCCESS = 'GET_CREDIT_SUCCESS'
export const GET_CREDIT_FAILED = 'GET_CREDIT_FAILED'
