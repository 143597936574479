import React from 'react'
import { Loading, Flex } from '@sharecover-co/ui'

const LoadingLayout = () => {
    return (
        <Flex sx={{ color: 'primary', minHeight: '90vh' }}>
            <Loading />
        </Flex>
    )
}

export default LoadingLayout
