import * as Yup from 'yup'
import DeclineMessages from '../../../components/base/DeclineMessages'
const schema = Yup.object().shape({
    address: Yup.object()
        .shape({
            formatted: Yup.string(),
            isManualAddress: Yup.boolean(),
            streetAddress: Yup.string().when('isManualAddress', {
                is: true,
                then: Yup.string().required('Required')
            }),
            suiteAddress: Yup.string()
                .when('isManualAddress', {
                    is: true,
                    then: Yup.string().nullable()
                })
                .nullable(),
            town: Yup.string()
                .when('isManualAddress', {
                    is: true,
                    then: Yup.string().nullable()
                })
                .nullable(),
            city: Yup.string().when('isManualAddress', {
                is: true,
                then: Yup.string().required('Required')
            }),
            region: Yup.string()
                .when('isManualAddress', {
                    is: true,
                    then: Yup.string().nullable()
                })
                .nullable(),
            postcode: Yup.string().when('isManualAddress', {
                is: true,
                then: Yup.string().required('Required')
            }),
            country: Yup.string().when('isManualAddress', {
                is: true,
                then: Yup.string().required('Required')
            })
        })
        .required('Required'),
    termsAndConditions: Yup.bool().oneOf([true], DeclineMessages.DM7),
    insuranceDeclined: Yup.bool().oneOf([false], DeclineMessages.DM8),
    criminalOffence: Yup.bool().oneOf([false], DeclineMessages.DM9)
})

export default schema
