const creditChoices = (credit, startDate, endDate) => {
    const date1 = new Date(startDate)
    const date2 = new Date(endDate)
    const diffTime = Math.abs(date2 - date1)
    const numberOfDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    let max = 0
    if (credit > numberOfDays) {
        max = numberOfDays
    } else {
        max = credit
    }
    let obj = {}
    for (let i = 0; i <= max; i++) {
        obj[i] = i
    }
    return obj
}

export default creditChoices
