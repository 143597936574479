import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box, Heading, Link, Text } from '@sharecover-co/ui'
import { useHistory } from 'react-router-dom'
import CheckoutLayout from '../../layouts/CheckoutLayout'
import { listPaymentMethods } from '../../../actions/paymentMethods'
import { addData } from '../../../actions/session'
import PaymentMethodForm from '../../forms/PaymentMethodForm'
import get from 'lodash.get'

const PaymentMethods = ({ listPaymentMethods, methods, addData }) => {
    const [isLoading, setLoading] = useState(true)
    const history = useHistory()

    useEffect(() => {
        const fetchData = async () => {
            await listPaymentMethods()
            setLoading(false)
        }

        fetchData()
    }, [listPaymentMethods])

    return (
        <CheckoutLayout title="Checkout" progress={40}>
            <Heading sx={{ fontSize: 2 }}>Select a payment method</Heading>
            {!isLoading && methods.length === 0 && (
                <Text>No payment records on account.</Text>
            )}
            {!isLoading && (
                <PaymentMethodForm
                    choices={Object.fromEntries(
                        methods.map((m) => [
                            m.id,
                            `${m.cardType} ending in ${m.last4} (${m.expirationMonth}/${m.expirationYear})`
                        ])
                    )}
                    onSubmit={async (values) => {
                        await addData(values)

                        history.push('/payment')
                    }}
                />
            )}
            <Box sx={{ my: 3 }}>
                <Link to="/account" sx={{ variant: 'links.buttonOutline' }}>
                    Back
                </Link>
            </Box>
        </CheckoutLayout>
    )
}

const mapStateToProps = (state) => ({
    methods: get(state, 'paymentMethods.methods', [])
})

const mapDispatchToProps = {
    listPaymentMethods,
    addData
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethods)
