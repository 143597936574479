import React from 'react'
import PropTypes from 'prop-types'
import set from 'lodash.set'
import get from 'lodash.get'
import { Box, Select, Field } from '@sharecover-co/ui'
import { useField, useFormikContext } from 'formik'
import { connect } from 'react-redux'

const formatAddress = (data) =>
    [
        [data.additionalAddress, data.suiteAddress, data.streetAddress]
            .filter((f) => f)
            .join('/'),
        data.town,
        data.city,
        [data.region, data.postcode].filter((f) => f).join(' '),
        data.country
    ]
        .filter((f) => f)
        .join(', ')

const Address = ({
    label,
    required,
    searchOptions,
    onPrefillChange,
    state,
    ...props
}) => {
    const [field] = useField(props)
    const { values, setFieldValue } = useFormikContext()

    // allow nesting
    const prefix = field.name.split('.').slice(0, -1).join('.')
    const buildPath = (k) => [prefix, k].filter((t) => t).join('.')
    const setValue = (k, v) => setFieldValue(buildPath(k), v)

    // on change handler to produce address format
    const onChange = (e) => {
        field.onChange(e)

        const newValues = { ...values }
        set(newValues, e.target.name, e.target.value)

        const address = formatAddress(
            prefix ? get(newValues, prefix) : newValues
        )

        if (address) {
            setValue('formatted', address)
        }
    }

    return (
        <Box sx={{ my: 3 }}>
            <Field
                name={buildPath(`streetAddress`)}
                label="Address Line 1"
                required
                onChange={onChange}
                placeholder="123 Jones Rd"
            />
            <Field
                name={buildPath(`suiteAddress`)}
                onChange={onChange}
                label="Address Line 2"
            />
            <Field
                name={buildPath(`additionalAddress`)}
                onChange={onChange}
                label="Address Line 3"
            />
            {/* <Field name={buildPath(`town`)} onChange={onChange} label="Town" /> */}
            <Field
                name={buildPath(`city`)}
                onChange={onChange}
                required
                label="Suburb/Town"
            />
            <Select
                name={buildPath(`region`)}
                onChange={onChange}
                label="State"
                placeholder="Select"
                required
                choices={{
                    ACT: 'ACT',
                    NSW: 'NSW',
                    NT: 'NT',
                    QLD: 'QLD',
                    SA: 'SA',
                    TAS: 'TAS',
                    VIC: 'VIC',
                    WA: 'WA'
                }}
            />
            <Field
                name={buildPath(`postcode`)}
                onChange={onChange}
                required
                label="Postcode"
            />
            <Field
                name={buildPath(`country`)}
                onChange={onChange}
                required
                value="AU"
                label="Country"
            />
        </Box>
    )
}

Address.propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool,
    searchOptions: PropTypes.shape({
        bounds: PropTypes.object,
        componentRestrictions: PropTypes.object,
        location: PropTypes.object,
        offset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        radius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        types: PropTypes.array
    })
}

Address.defaultProps = {
    label: 'Address',
    required: false,
    searchOptions: {
        location: {
            lat: () => -34,
            lng: () => 151
        },
        radius: 4000
    }
}

const mapStateToProps = (state) => ({
    state
})

export default connect(mapStateToProps)(Address)
