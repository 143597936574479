import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Radio, Submit, Box, Link, Text } from '@sharecover-co/ui'
import * as Yup from 'yup'
import { Form } from 'formik'
import FormManager from './FormManager'
import { connect } from 'react-redux'

const schema = Yup.object().shape({
    paymentMethodId: Yup.string().required('Required')
})

const PaymentMethodForm = ({ choices, state, ...props }) => (
    <FormManager
        useSession
        validationSchema={schema}
        initialValues={{ paymentMethodId: '' }}
        {...props}
    >
        {({ status: { error } }) => (
            <Form>
                {error && <Alert>{error}</Alert>}
                {Object.keys(choices).length > 0 && (
                    <Radio
                        name="paymentMethodId"
                        label="How would you like to pay?"
                        required
                        choices={choices}
                    />
                )}
                <Box sx={{ my: 3 }}>
                    <Link to="/payment-method/new">Add new payment method</Link>
                    <br />
                    <br />
                    {state.product.product.id ===
                        process.env.REACT_APP_RIDECOVER_PRODUCT_ID && (
                        <small>
                            {' '}
                            You can select to pay upfront or monthly instalments
                            next.
                        </small>
                    )}
                </Box>

                {Object.keys(choices).length > 0 && <Submit label="Next" />}
            </Form>
        )}
    </FormManager>
)
const mapStateToProps = (state) => ({
    state
})

PaymentMethodForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
}

export default connect(mapStateToProps)(PaymentMethodForm)
