import React from 'react'
import { Route, Switch } from 'react-router-dom'

import NewQuote from '../views/NewQuote'
import Account from '../views/Account'
import Profile from '../views/Profile'
import PaymentMethod from '../views/PaymentMethod'
import Payment from '../views/Payment'
import Consent from '../views/Consent'
import Review from '../views/Review'
import Done from '../views/Done'
import NotFound from '../views/NotFound'
import IsAuthenticated from '../auth/IsAuthenticated'
import ProtectedRoute from '../auth/ProtectedRoute'
import withTracker from '../../utils/withTracker'

const Routes = () => (
    <IsAuthenticated>
        <Switch>
            <ProtectedRoute
                path="/quote/:id"
                component={withTracker(NewQuote)}
            />
            <ProtectedRoute path="/account" component={withTracker(Account)} />
            <ProtectedRoute path="/profile" component={withTracker(Profile)} />
            <ProtectedRoute
                path="/payment-method"
                component={withTracker(PaymentMethod)}
            />
            <ProtectedRoute path="/payment" component={withTracker(Payment)} />
            <ProtectedRoute path="/consent" component={withTracker(Consent)} />
            <ProtectedRoute path="/review" component={withTracker(Review)} />
            <ProtectedRoute path="/done" component={withTracker(Done)} />
            <Route component={withTracker(NotFound)} />
        </Switch>
    </IsAuthenticated>
)

export default Routes
