import * as actions from '../actions'
import get from 'lodash.get'

const INITIAL_STATE = {
    loading: false,
    profile: null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.NEW_SESSION:
            return { ...INITIAL_STATE }

        case actions.GET_PROFILE_REQUEST:
            return { ...INITIAL_STATE, loading: true }

        case actions.GET_PROFILE_SUCCESS:
            return { ...INITIAL_STATE, profile: action.item }

        case actions.UPDATE_ADDRESS_SUCCESS:
            return {
                ...INITIAL_STATE,
                profile: {
                    ...INITIAL_STATE.profile,
                    address: get(action, 'item.address')
                }
            }

        default:
            return state
    }
}
