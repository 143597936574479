import { API, graphqlOperation } from 'aws-amplify'
import { customAlphabet } from 'nanoid'
import get from 'lodash.get'
import * as actions from './index'
import * as mutations from '../graphql/mutations'
import jwt from '../lib/jwt'
import Cookies from 'js-cookie'
const nanoid = customAlphabet('0123456789ABCDEFGHJKLMNPQRTUVWXYZ', 10)
const generatePolicyId = (prefix) =>
    [prefix, nanoid()].filter((t) => t).join('-')

// addPolicy
const addPolicyRequest = (data) => ({
    type: actions.ADD_POLICY_REQUEST,
    data
})
const addPolicySuccess = (item) => ({
    type: actions.ADD_POLICY_SUCCESS,
    item
})
const addPolicyFailed = (error) => ({
    type: actions.ADD_POLICY_FAILED,
    error
})

export const addPolicy = () => async (dispatch, getState) => {
    const {
        session: { data },
        product: { product },
        credit: { credit },
        quote: { quote }
    } = getState()

    const token =
        Cookies.get('x-iag-user-id', {
            domain: `.${process.env.REACT_APP_DOMAIN_NAME}`
        }) ||
        Cookies.get('x-iag-customer-id', {
            domain: `.${process.env.REACT_APP_DOMAIN_NAME}`
        })
    const email = jwt.getEmail(token)
    let id = generatePolicyId(get(product, 'policyIdPrefix'))
    if (quote.data?.renewalPolicyId) {
        let postfix = ''
        let splitId = quote.data.renewalPolicyId.split('-')
        if (splitId.length === 2) postfix = '2'
        else postfix = `${splitId[2] * 1 + 1}`
        id = `${splitId[0]}-${splitId[1]}-${postfix}`
    }
    let input = {
        id,
        ...data
    }

    dispatch(addPolicyRequest(input))

    try {
        const response = await API.graphql(
            graphqlOperation(mutations.addPolicyByQuote, { input })
        )

        if (JSON.parse(response.data.addPolicyByQuote.data).credit * 1) {
            input = {
                id: nanoid(),
                email: email.toLowerCase(),
                policyId: response.data.addPolicyByQuote.id,
                credit:
                    JSON.parse(response.data.addPolicyByQuote.data).credit * 1,
                quoteId: response.data.addPolicyByQuote.quoteId,
                type: 'used'
            }
            await API.graphql(
                graphqlOperation(mutations.addcreditLog, { input })
            )
            input = {
                ...credit,
                credit:
                    credit.credit -
                    JSON.parse(response.data.addPolicyByQuote.data).credit * 1
            }
            await API.graphql(
                graphqlOperation(mutations.updateCredit, { input })
            )
        }
        dispatch(addPolicySuccess(response.data.addPolicyByQuote))

        return response.data.addPolicyByQuote
    } catch (e) {
        if (Array.isArray(e.errors) && e.errors[0].message) {
            dispatch(addPolicyFailed(e.errors[0].message))
            throw Error(e.errors[0].message)
        } else {
            dispatch(addPolicyFailed(e.message))
            throw e
        }
    }
}
