import React, { useEffect, useRef, useState } from 'react'
import AdyenCheckout from '@adyen/adyen-web'
import '@adyen/adyen-web/dist/adyen.css'
import { API } from 'aws-amplify'
import { Box, Button, Flex, Loading } from '@sharecover-co/ui'
import { connect } from 'react-redux'

const AdyenForm = ({ onSubmit, state }) => {
    const paymentContainer = useRef()
    const [instance, setInstance] = useState()
    const [methods, setMethods] = useState()
    const [submitting, setSubmitting] = useState(false)

    useEffect(() => {
        API.get('API', '/clp/payment/methods').then((methods) => {
            setMethods(methods)

            const configuration = {
                paymentMethodsResponse: methods.gatewayResponse,
                clientKey: methods.clientKey,
                locale: 'en-AU',
                environment: process.env.REACT_APP_ADYEN_ENV,
                onSubmit: async ({ data: card }) => {
                    try {
                        // Exchange card data for a paymentAccountId
                        const { paymentMethod, storePaymentMethod } = card
                        const data = await API.post(
                            'API',
                            '/clp/payment/methods/new',
                            {
                                body: {
                                    paymentMethod,
                                    storePaymentMethod
                                }
                            }
                        )
                        onSubmit(data.paymentAccountDetails || data)
                    } catch (error) {
                        console.log(error)
                        alert('Please check your card details and try again.')
                        throw error
                    } finally {
                        setSubmitting(false)
                    }
                },
                paymentMethodsConfiguration: {
                    card: {
                        hasHolderName: true,
                        holderNameRequired: true,
                        enableStoreDetails: false,
                        hideCVC: false,
                        name: 'Credit or debit card'
                    }
                },
                showStoredPaymentMethods: false,
                showPayButton: false
            }

            return new AdyenCheckout(configuration)
                .then((checkout) => {
                    const dropin = checkout
                        .create('dropin')
                        .mount(paymentContainer.current)
                    setInstance(dropin)
                })
                .catch((err) => {
                    console.log(err)
                    throw err
                })
        })
    }, [onSubmit])

    const submit = () => {
        setSubmitting(true)
        instance.submit()
    }

    const CSS = `
        .adyen-checkout__payment-method {
            font-size: 1rem;
        }
        .adyen-checkout__label__text {
            line-height: 1rem;
        }
        .adyen-checkout__icon {
            display: inherit;
        }
    `

    return (
        <>
            {methods ? (
                <>
                    <Box sx={{ my: 3 }}>
                        <Box ref={paymentContainer}>
                            <style>{CSS}</style>
                        </Box>
                    </Box>
                    {state.product.product.id ===
                        process.env.REACT_APP_RIDECOVER_PRODUCT_ID && (
                        <small>
                            {' '}
                            You can select to pay upfront or monthly instalments
                            next.
                        </small>
                    )}
                    <br />
                    <br />
                    {instance && (
                        <Button onClick={submit} disabled={submitting}>
                            Add
                        </Button>
                    )}
                </>
            ) : (
                <Flex sx={{ color: 'primary', my: 5 }}>
                    <Loading />
                </Flex>
            )}
        </>
    )
}

const mapStateToProps = (state) => ({
    state
})

export default connect(mapStateToProps)(AdyenForm)
