import React from 'react'
import { connect } from 'react-redux'
import { Heading, Link, Box, Loading, Alert } from '@sharecover-co/ui'
import { useHistory } from 'react-router-dom'
import CheckoutLayout from '../layouts/CheckoutLayout'
import ConfirmForm from '../forms/ConfirmForm'
import { addPolicy } from '../../actions/policy'
import { debounce } from 'lodash'
const Review = ({ addPolicy, state }) => {
    const history = useHistory()
    const [purchased, setPurchased] = React.useState(false)
    React.useEffect(() => {
        if (state.policy.loading) {
            window.history.pushState(null, document.title, window.location.href)
            window.addEventListener('popstate', function (event) {
                window.history.pushState(
                    null,
                    document.title,
                    window.location.href
                )
            })
            setPurchased(true)
        } else {
            setPurchased(false)
        }
    }, [state.policy])
    return (
        <CheckoutLayout title="Checkout" progress={99}>
            {purchased ? (
                <Loading />
            ) : (
                <>
                    <Heading sx={{ fontSize: 2 }}>Review</Heading>
                    {state.policy.error && <Alert>{state.policy.error}</Alert>}
                    <ConfirmForm
                        onSubmit={async () => {
                            setPurchased(true)
                            const debouncedAdd = debounce(async function () {
                                await addPolicy()
                                history.push('/done')
                            }, 2000)
                            debouncedAdd()
                        }}
                    />

                    <Box sx={{ my: 3 }}>
                        <Link
                            to="/consent"
                            sx={{ variant: 'links.buttonOutline' }}
                            disabled
                        >
                            Back
                        </Link>
                    </Box>
                </>
            )}
        </CheckoutLayout>
    )
}
const mapStateToProps = (state) => ({
    state
})

const mapDispatchToProps = {
    addPolicy
}

export default connect(mapStateToProps, mapDispatchToProps)(Review)
