import { API, graphqlOperation } from 'aws-amplify'
import get from 'lodash.get'
import * as actions from './index'
import * as mutations from '../graphql/mutations'
import pick from 'lodash.pick'

const convertEmptyValues = (Item) => {
    return JSON.parse(
        JSON.stringify(Item, (_, value) => {
            if (value === '' || value === null || value === undefined) {
                return undefined
            }
            return value
        })
    )
}

// updateaddress
const updateAddressRequest = (data) => ({
    type: actions.UPDATE_ADDRESS_REQUEST,
    data
})
const updateAddressSuccess = (item) => ({
    type: actions.UPDATE_ADDRESS_SUCCESS,
    item
})
const updateAddressFailed = (error) => ({
    type: actions.UPDATE_ADDRESS_FAILED,
    error
})

export const updateAddress = (data, productName) => async (dispatch) => {
    let inputItems = []
    let condition =
        productName === process.env.REACT_APP_LONG_HOMECOVER_PRODUCT_ID ||
        productName === process.env.REACT_APP_SHORT_HOMECOVER_PRODUCT_ID
    if (condition)
        inputItems = [
            'address',
            'termsAndConditions',
            'insuranceDeclined',
            'criminalOffence'
        ]
    else inputItems = ['address']
    const input = convertEmptyValues(pick(data, inputItems))
    dispatch(updateAddressRequest(input))

    try {
        const response = await API.graphql(
            graphqlOperation(mutations.updateProfile, { input })
        )

        dispatch(updateAddressSuccess(response.data.updateProfile))
    } catch (e) {
        dispatch(
            updateAddressFailed(
                get(
                    e,
                    'errors[0].message',
                    get(e, 'message', 'Unable to update address.')
                )
            )
        )
        throw e
    }
}
