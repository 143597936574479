import React from 'react'
import { connect } from 'react-redux'
import { Box, Heading, Button } from '@sharecover-co/ui'
import { useHistory } from 'react-router-dom'
import CheckoutLayout from '../../layouts/CheckoutLayout'
import AdyenForm from '../../forms/AdyenForm'
import { addPaymentMethod } from '../../../actions/paymentMethods'
import { addData } from '../../../actions/session'

const NewPaymentMethod = ({ addPaymentMethod, addData }) => {
    const history = useHistory()

    return (
        <CheckoutLayout title="Checkout" progress={40}>
            <Heading sx={{ fontSize: 2 }}>New Payment Method</Heading>
            <AdyenForm
                onSubmit={async (card) => {
                    const { id } = await addPaymentMethod({
                        gateway: 'CLP',
                        token: card.paymentAccountId,
                        cardType: card.cardInfo.cardBrand,
                        last4: card.cardInfo.lastFour,
                        expirationMonth: card.cardInfo.expiryMonth,
                        expirationYear: card.cardInfo.expiryYear
                    })

                    addData({ paymentMethodId: id })

                    history.push('/payment')
                }}
            />
            <Box sx={{ my: 3 }}>
                <Button onClick={() => window.history.go(-1)} variant="outline">
                    Back
                </Button>
            </Box>
        </CheckoutLayout>
    )
}

const mapDispatchToProps = {
    addPaymentMethod,
    addData
}

export default connect(null, mapDispatchToProps)(NewPaymentMethod)
