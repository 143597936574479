import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box, Header, Loading, Link, Divider } from '@sharecover-co/ui'
import Pricing from '../base/Pricing'
import Product from '../base/Product'
import ExitModal from '../modals/ExitModal'
import useMediaQuery from 'use-mediaquery'
const CheckoutLayout = ({
    progress,
    title,
    menu,
    product,
    loading,
    children
}) => {
    const matches = useMediaQuery('(max-width: 767px)')
    return (
        <Box>
            <Header progress={progress} title={title} menu={menu} />
            {loading && (
                <Flex
                    sx={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        bg: 'rgba(255, 255, 255, 80%)',
                        color: 'primary'
                    }}
                >
                    <Loading />
                </Flex>
            )}
            {!loading && (
                <>
                    <Flex
                        variant="fixed"
                        sx={
                            matches
                                ? {
                                      p: [0, 0, 0, 0, 2],
                                      justifyContent: 'space-evenly',
                                      flexDirection: [
                                          'column',
                                          'column',
                                          'row'
                                      ],
                                      mb: [140, 0],
                                      boxSizing: 'border-box',
                                      margin: '0px',
                                      minWidth: '0px',
                                      width: '100%'
                                  }
                                : {
                                      p: [0, 0, 0, 0, 2],
                                      justifyContent: 'space-evenly',
                                      flexDirection: [
                                          'column',
                                          'column',
                                          'row'
                                      ],
                                      mb: [140, 0],
                                      boxSizing: 'border-box',
                                      margin: '0px',
                                      minWidth: '0px',
                                      width: '100%',

                                      position: 'fixed'
                                      // height: '100vh'
                                  }
                        }
                    >
                        <Box
                            sx={{
                                p: [3, 4],
                                mb: 2,
                                flex: 1,
                                maxWidth: ['auto', 'auto', 'auto', 600],
                                height: '80vh',
                                overflow: 'scroll'
                            }}
                        >
                            {children}
                        </Box>
                        <Box
                            sx={{
                                p: [3, 4],
                                flex: 1,
                                maxWidth: ['auto', 'auto', 'auto', 600],
                                height: '80vh',
                                overflow: 'scroll'
                            }}
                        >
                            <Pricing />
                            {product && (
                                <Box sx={{ p: [2, 3, 4], mt: 1 }}>
                                    {product}
                                </Box>
                            )}
                            <Divider sx={{ my: 0 }} />
                            <Box sx={{ p: [2, 3, 4] }}>
                                <p>
                                    <Link
                                        href={`https://${process.env.REACT_APP_DOMAIN_NAME}/contact`}
                                        target="_blank"
                                    >
                                        Contact Us
                                    </Link>
                                </p>
                                <p>
                                    <Link
                                        href={`https://${process.env.REACT_APP_DOMAIN_NAME}/terms-of-use`}
                                        target="_blank"
                                    >
                                        By proceeding, you agree to the
                                        ShareCover website Terms of Use
                                    </Link>
                                </p>
                                <p>
                                    <Link
                                        href={`https://${process.env.REACT_APP_DOMAIN_NAME}/complaints`}
                                        target="_blank"
                                    >
                                        Complaints Process
                                    </Link>
                                </p>
                                <p>
                                    <Link
                                        href={`https://${process.env.REACT_APP_DOMAIN_NAME}/privacy-and-security`}
                                        target="_blank"
                                    >
                                        We will collect, use and disclose your
                                        details in accordance with our privacy
                                        policy
                                    </Link>
                                </p>
                            </Box>
                        </Box>
                    </Flex>
                </>
            )}
        </Box>
    )
}

CheckoutLayout.propTypes = {
    children: PropTypes.any,
    title: PropTypes.string,
    progress: PropTypes.number,
    menu: PropTypes.node,
    product: PropTypes.node,
    loading: PropTypes.bool
}

CheckoutLayout.defaultProps = {
    children: null,
    title: '',
    progress: 0,
    menu: <ExitModal />,
    product: <Product />,
    loading: false
}

export default CheckoutLayout
