import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { getProfile } from '../../actions/profile'
import Routes from './Routes'

class App extends Component {
    async componentDidMount() {
        getProfile()
    }

    render() {
        return <Routes />
    }
}

export default withRouter(App)
