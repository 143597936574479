import { API, graphqlOperation } from 'aws-amplify'
import * as actions from './index'
// import * as queries from '../graphql/queries'

// getQuote
const getQuoteRequest = (id, accessKey) => ({
    type: actions.GET_QUOTE_REQUEST,
    id,
    accessKey
})
const getQuoteSuccess = (item) => ({
    type: actions.GET_QUOTE_SUCCESS,
    item
})
const getQuoteFailed = (error) => ({
    type: actions.GET_QUOTE_FAILED,
    error
})

export const getQuoteQuery = /* GraphQL */ `
    query GetQuote($id: ID!, $accessKey: ID) {
        getQuote(id: $id, accessKey: $accessKey) {
            id
            userId
            data
            startDate
            startTime
            endDate
            endTime
            product {
                id
                name
                description
                quoteFlowId
                quoteFlow {
                    id
                    fields {
                        name
                        path
                        format
                        config
                    }
                    name
                    url
                    createdAt
                    updatedAt
                }
                premiumPriceId
                unitPriceId
                paymentSchedules
                policyIdPrefix
                policyDescriptionPath
                documents {
                    name
                    key
                    url
                }
                createdAt
                updatedAt
            }
            premium {
                price
                taxes {
                    tax
                    price
                }
                tax
                total
            }
            unit {
                price
                taxes {
                    tax
                    price
                }
                tax
                total
            }
            accessKey
            ttl
            createdAt
        }
    }
`

export const getQuote = (id, accessKey) => async (dispatch) => {
    dispatch(getQuoteRequest(id, accessKey))

    try {
        const response = await API.graphql(
            graphqlOperation(getQuoteQuery, { id, accessKey })
        )

        if (!response.data.getQuote) {
            throw new Error('No quote found.')
        }

        dispatch(getQuoteSuccess(response.data.getQuote))
    } catch (e) {
        dispatch(getQuoteFailed(e.message))
        throw e
    }
}
export const reduxForUpdateQuote = (id, accessKey) => async (dispatch) => {
    try {
        dispatch({
            type: actions.GET_QUOTE_REQUEST,
            id: id,
            accessKey: accessKey
        })
    } catch (error) {
        throw error
    }
}
