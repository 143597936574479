import * as actions from '../actions'

const INITIAL_STATE = {
    methods: []
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.LIST_PAYMENT_METHODS_SUCCESS:
            return { ...state, methods: action.items }

        case actions.ADD_PAYMENT_METHOD_SUCCESS:
            return { ...state, methods: [...state.methods, action.item] }

        default:
            return state
    }
}
