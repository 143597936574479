import React, { useEffect, useState } from 'react'
import { Link, Box, Button } from '@sharecover-co/ui'
import { useHistory } from 'react-router-dom'
import CheckoutLayout from '../layouts/CheckoutLayout'

// const SubmitBtn = () => <Submit label="Continue" />

const Consent = () => {
    const history = useHistory()
    const [embedMethods, setEmbedMethods] = useState()

    useEffect(() => {
        setEmbedMethods(
            window.iagCiamEmbeds.embed({
                name: 'communications',
                domElementId: 'consent',
                environment: process.env.REACT_APP_CLP_ENV,
                brand: 'sharecover'
            })
        )
    }, [])

    return (
        <CheckoutLayout title="Checkout" progress={80}>
            <Box
                id="consent"
                sx={{
                    iframe: {
                        border: 'none'
                    }
                }}
            />

            <Button
                onClick={() =>
                    embedMethods.attemptToSubmitCurrentPage().then((result) => {
                        console.log(
                            `submitted consent: ${
                                result ? 'success' : 'failed '
                            }`
                        )
                        // we browse to next page only on success result
                        result && history.push('/review')
                    })
                }
            >
                Submit
            </Button>

            <Box sx={{ my: 3 }}>
                <Link to="/payment" sx={{ variant: 'links.buttonOutline' }}>
                    Back
                </Link>
            </Box>
        </CheckoutLayout>
    )
}

export default Consent
