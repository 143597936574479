import omit from 'lodash.omit'
import * as actions from './'

export const newSession = (data) => ({ type: actions.NEW_SESSION, data })

export const addData = (values, replace = true) => async (
    dispatch,
    getState
) => {
    const { data: currentValues } = getState().session
    const data = replace ? values : omit(values, Object.keys(currentValues))

    // dont bother if nothing has changed
    if (
        Object.keys(data).filter((k) => data[k] !== currentValues[k]).length ===
        0
    ) {
        return
    }

    await dispatch({ type: actions.ADD_DATA, data })
}
