import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import useIsMounted from '../hooks/useIsMounted'

const INITIAL_STATUS = { success: false, error: null }

const FormManager = ({ onSubmit, useSession, session, ...props }) => {
    const isMounted = useIsMounted()

    // allow automatic use of session data
    if (useSession) {
        Object.keys(session)
            .filter((key) => key in props.initialValues)
            .forEach((key) => (props.initialValues[key] = session[key]))
    }

    return (
        <Formik
            initialStatus={{ ...INITIAL_STATUS }}
            onSubmit={(values, { setSubmitting, setStatus }) => {
                const result = onSubmit(values)

                if (result instanceof Promise)
                    result
                        .then(() => {
                            if (isMounted.current) {
                                setSubmitting(false)
                                setStatus({ ...INITIAL_STATUS, success: true })
                            }
                        })
                        .catch((error) => {
                            if (isMounted.current) {
                                setSubmitting(false)
                                setStatus({
                                    ...INITIAL_STATUS,
                                    error: error.message
                                })
                            }

                            throw error
                        })
            }}
            {...props}
        />
    )
}

FormManager.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    useSession: PropTypes.bool
}

FormManager.defaultProps = {
    useSession: false
}

const mapStateToProps = (state) => ({
    session: state.session.data
})

export default connect(mapStateToProps)(FormManager)
