import { Link } from '@sharecover-co/ui'
const shareCoverUrl = `${process.env.REACT_APP_MARKETING_URL}`
const shareCoverDomain = shareCoverUrl.substr('https://'.length)

const declineMessages = {
    DM7: (
        <>
            <p>
                Based on your response to the acknowledgment above, we regret
                that we cannot offer you a quote for insurance at this time. You
                can ask us for the information we relied on in assessing your
                application for insurance by going to{' '}
                <Link href={`${shareCoverUrl}/contact/`} target="_blank">
                    {`${shareCoverDomain}/contact`}
                </Link>
                .
                <br />
                <br />
                For information on your options for alternative insurance you
                can access the Insurance Council of Australia's 'Find an
                Insurer' service at{' '}
                <Link
                    href={`https://www.findaninsurer.com.au/`}
                    target="_blank"
                >
                    {`www.findaninsurer.com.au`}
                </Link>
                .
                <br />
                <br />
                If you are unhappy with our decision, you can access our
                complaints process by going to{' '}
                <Link href={`${shareCoverUrl}/complaints/`} target="_blank">
                    {`${shareCoverDomain}/complaints`}
                </Link>
                .
            </p>
        </>
    ),
    DM8: (
        <>
            <p>
                Based on the information you have provided about your insurance
                history and our underwriting guidelines, we regret that we
                cannot offer you insurance at this time. You can ask us for the
                information we relied on in assessing your application for
                insurance by contacting us at{' '}
                <Link href={`${shareCoverUrl}/contact/`} target="_blank">
                    {`${shareCoverDomain}/contact`}
                </Link>
                .
                <br />
                <br />
                For information on your options for alternative insurance you
                can access the Insurance Council of Australia's 'Find an
                Insurer' service at{' '}
                <Link
                    href={`https://www.findaninsurer.com.au/`}
                    target="_blank"
                >
                    {`www.findaninsurer.com.au`}
                </Link>
                .
                <br />
                <br />
                If you are unhappy with our decision, you can access our
                complaints process by going to{' '}
                <Link href={`${shareCoverUrl}/complaints/`} target="_blank">
                    {`${shareCoverDomain}/complaints`}
                </Link>
                .
            </p>
        </>
    ),
    DM9: (
        <>
            <p>
                Based on the information you have provided about previous
                convictions and our underwriting guidelines, we regret that we
                cannot offer you insurance at this time. You can ask us for the
                information we relied on in assessing your application for
                insurance by contacting us at{' '}
                <Link href={`${shareCoverUrl}/contact/`} target="_blank">
                    {`${shareCoverDomain}/contact`}
                </Link>
                .
                <br />
                <br />
                For information on your options for alternative insurance you
                can access the Insurance Council of Australia's 'Find an
                Insurer' service at{' '}
                <Link
                    href={`https://www.findaninsurer.com.au/`}
                    target="_blank"
                >
                    {`www.findaninsurer.com.au`}
                </Link>
                .
                <br />
                <br />
                If you are unhappy with our decision, you can access our
                complaints process by going to{' '}
                <Link href={`${shareCoverUrl}/complaints/`} target="_blank">
                    {`${shareCoverDomain}/complaints`}
                </Link>
                .
            </p>
        </>
    )
}

export default declineMessages
