import { API, graphqlOperation } from 'aws-amplify'
import { nanoid } from 'nanoid'
import * as actions from './index'
import * as queries from '../graphql/queries'
import * as mutations from '../graphql/mutations'

// listPaymentMethods
const listPaymentMethodsRequest = () => ({
    type: actions.LIST_PAYMENT_METHODS_REQUEST
})
const listPaymentMethodsSuccess = (items) => ({
    type: actions.LIST_PAYMENT_METHODS_SUCCESS,
    items
})
const listPaymentMethodsFailed = (error) => ({
    type: actions.LIST_PAYMENT_METHODS_FAILED,
    error
})

export const listPaymentMethods = () => async (dispatch) => {
    dispatch(listPaymentMethodsRequest())

    try {
        const response = await API.graphql(
            graphqlOperation(queries.listPaymentMethods)
        )

        dispatch(listPaymentMethodsSuccess(response.data.listPaymentMethods))
    } catch (e) {
        dispatch(listPaymentMethodsFailed(e.message))
        throw e
    }
}

// addPaymentMethod
const addPaymentMethodRequest = (data) => ({
    type: actions.ADD_PAYMENT_METHOD_REQUEST,
    data
})
const addPaymentMethodSuccess = (items) => ({
    type: actions.ADD_PAYMENT_METHOD_SUCCESS,
    items
})
const addPaymentMethodFailed = (error) => ({
    type: actions.ADD_PAYMENT_METHOD_FAILED,
    error
})

export const addPaymentMethod = (data) => async (dispatch) => {
    const input = {
        id: nanoid(),
        ...data
    }

    dispatch(addPaymentMethodRequest(input))

    try {
        const response = await API.graphql(
            graphqlOperation(mutations.addPaymentMethod, { input })
        )

        dispatch(addPaymentMethodSuccess(response.data.addPaymentMethod))

        return response.data.addPaymentMethod
    } catch (e) {
        dispatch(addPaymentMethodFailed(e.message))
        throw e
    }
}
