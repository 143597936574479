import React from 'react'
import { connect } from 'react-redux'
import { Heading, Text, Box, Link, Tag } from '@sharecover-co/ui'
import CheckoutLayout from '../layouts/CheckoutLayout'

const Done = ({ policy }) => {
    const nextUrl = `https://my.${process.env.REACT_APP_DOMAIN_NAME}/policy/${policy.id}`
    window.history.pushState(null, document.title, window.location.href)
    window.addEventListener('popstate', function (event) {
        window.history.pushState(null, document.title, window.location.href)
    })
    return (
        <CheckoutLayout
            title="Success"
            progress={100}
            menu={<Link href={nextUrl}>View Policy</Link>}
        >
            <Heading sx={{ mb: 1, fontSize: 2 }}>You're all done!</Heading>
            <Text>
                Your ShareCover policy number is <Tag>{policy.id}</Tag>. We have
                emailed your confirmation and Certificate of Insurance. You can
                see all the details of your policy in your ShareCover dashboard.
            </Text>
            <Box sx={{ my: 3 }}>
                <Link href={nextUrl} sx={{ variant: 'links.button' }}>
                    View Policy
                </Link>
            </Box>
        </CheckoutLayout>
    )
}

const mapStateToProps = (state) => ({
    ...state.policy
})

export default connect(mapStateToProps)(Done)
