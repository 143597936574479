import React from 'react'
import { connect } from 'react-redux'
import { Select } from '@sharecover-co/ui'
import { useFormikContext } from 'formik'
import * as queries from '../../../graphql/queries'
import { API, graphqlOperation } from 'aws-amplify'
const PreviousAddress = (props) => {
    const formik = useFormikContext()
    const [previousAddress, setPreviousAddress] = React.useState(null)
    React.useEffect(() => {
        API.graphql(graphqlOperation(queries.getProfile)).then((profile) => {
            setPreviousAddress(profile.data.getProfile.address)
        })
    }, [])

    return previousAddress ? (
        <Select
            name="previousAddress"
            id="previousAddress"
            label="Please select"
            choices={{
                newAddress: 'New address',
                previousAddress: `${previousAddress?.formatted}`
            }}
            onChange={(e) => {
                formik.handleChange(e)
                formik.setFieldValue(
                    'address',
                    e.target.value === 'newAddress'
                        ? {
                              formatted: '',
                              isManualAddress: true,
                              suiteAddress: '',
                              additionalAddress: '',
                              streetAddress: '',
                              town: '',
                              city: '',
                              region: '',
                              postcode: '',
                              country: 'AU',
                              placeId: '',
                              lat: '',
                              lng: ''
                          }
                        : {
                              ...previousAddress,
                              country: 'AU',
                              isManualAddress: true,
                              formatted:
                                  previousAddress.formatted
                                      .split(',')
                                      .splice(
                                          0,
                                          previousAddress.formatted.split(',')
                                              .length - 1
                                      )
                                      .join(',') + ', AU'
                          }
                )
            }}
        />
    ) : (
        <></>
    )
}

const mapStateToProps = (state) => ({
    ...state
})

export default connect(mapStateToProps)(PreviousAddress)
