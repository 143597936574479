/**
 * parseJwt.
 *
 * @param {} token
 */
function parseJwt(token) {
    var base64Payload = token.split('.')[1]
    var payload = Buffer.from(base64Payload, 'base64')
    return JSON.parse(payload.toString())
}

/**
 * getData.
 *
 * @param {} data
 */
function getData(data) {
    return function (token) {
        if (!token) return ''
        return parseJwt(token)[data]
    }
}

export default {
    getEmail: getData('email'),
    getName: getData('name'),
    getPhone: getData('mobile_number'),
    getDob: getData('date_of_birth'),
    getExp: getData('exp')
}
