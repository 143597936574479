import React from 'react'
import { connect } from 'react-redux'
import { Heading, Link, Box } from '@sharecover-co/ui'
import { useHistory } from 'react-router-dom'
import get from 'lodash.get'
import CheckoutLayout from '../layouts/CheckoutLayout'
import PaymentForm from '../forms/PaymentForm'
import { addData } from '../../actions/session'

const Payment = ({ addData, quote }) => {
    const history = useHistory()

    return (
        <CheckoutLayout title="Checkout" progress={60}>
            <Heading sx={{ fontSize: 2 }}>Payment</Heading>
            <PaymentForm
                quote={quote}
                onSubmit={async (values) => {
                    await addData(values)

                    history.push('/consent')
                }}
            />
            <Box sx={{ my: 3 }}>
                <Link
                    to="/payment-method"
                    sx={{ variant: 'links.buttonOutline' }}
                >
                    Back
                </Link>
            </Box>
        </CheckoutLayout>
    )
}

const mapDispatchToProps = {
    addData
}

const mapStateToProps = (state) => ({
    quote: get(state, 'quote.quote')
})

export default connect(mapStateToProps, mapDispatchToProps)(Payment)
