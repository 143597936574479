import React from 'react'
import { Route, Switch } from 'react-router-dom'

import PaymentMethods from './payment-method/PaymentMethods'
import NewPaymentMethod from './payment-method/NewPaymentMethod'
import withTracker from '../../utils/withTracker'

const PaymentMethod = () => {
    return (
        <Switch>
            <Route
                path="/payment-method"
                exact
                component={withTracker(PaymentMethods)}
            />
            <Route
                path="/payment-method/new"
                exact
                component={withTracker(NewPaymentMethod)}
            />
        </Switch>
    )
}

export default PaymentMethod
