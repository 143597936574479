import { combineReducers } from 'redux'
import session from './session'
import product from './product'
import quote from './quote'
import policy from './policy'
import paymentMethods from './paymentMethods'
import profile from './profile'
import credit from './credit'

export default combineReducers({
    session,
    product,
    quote,
    policy,
    paymentMethods,
    profile,
    credit
})
