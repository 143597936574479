import React from 'react'
import { connect } from 'react-redux'
import { Heading, Link } from '@sharecover-co/ui'
import { nanoid } from 'nanoid'

const Product = ({ product }) => {
    if (!product) {
        return null
    }

    const { name, description, documents } = product

    return (
        <>
            <Heading sx={{ fontSize: [1, 1] }}>{name}</Heading>
            {description && <p>{description}</p>}
            {documents.map((doc) => (
                <p key={nanoid()}>
                    <Link href={doc.url} target="_blank">
                        {doc.name}
                    </Link>
                </p>
            ))}
        </>
    )
}

const mapStateToProps = (state) => ({
    ...state.product
})

export default connect(mapStateToProps)(Product)
