import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Divider, Heading, Label, Link } from '@sharecover-co/ui'
import { useHistory } from 'react-router-dom'
import CheckoutLayout from '../layouts/CheckoutLayout'
import AddressForm from '../forms/AddressForm'
import Jwt from '../../lib/jwt'
import Cookies from 'js-cookie'
import { updateAddress } from '../../actions/address'
import get from 'lodash.get'
import { existy } from '../../lib/fn'
import { getCredit } from '../../actions/credit'
const figureName = (profile, token) => {
    return profile && profile.firstName && profile.lastName
        ? `${profile.firstName} ${profile.lastName}`
        : Jwt.getName(token)
}

const Profile = ({ loading, profile, updateAddress, state, getCredit }) => {
    const [selectedCredits, setSelectedCredits] = useState(0)
    const history = useHistory()
    const token =
        Cookies.get('x-iag-user-id', {
            domain: `.${process.env.REACT_APP_DOMAIN_NAME}`
        }) ||
        Cookies.get('x-iag-customer-id', {
            domain: `.${process.env.REACT_APP_DOMAIN_NAME}`
        })

    const getSelectedCredits = (value) => {
        setSelectedCredits(value)
    }
    useEffect(() => {
        const getCredits = async () => {
            try {
                await getCredit(
                    (profile && profile.email) || Jwt.getEmail(token)
                )
            } catch (err) {
                throw err
            }
        }
        getCredits()
    }, [])
    return (
        <CheckoutLayout title="Checkout" progress={30} loading={loading}>
            <Heading sx={{ fontSize: 2 }}>Profile</Heading>
            <div>
                <Label description={<p>{figureName(profile, token)}</p>}>
                    Name
                </Label>
                <Label
                    description={
                        <p>{(profile && profile.dob) || Jwt.getDob(token)}</p>
                    }
                >
                    Date of Birth
                </Label>
                <Label
                    description={
                        <p>
                            {(profile && profile.phone) || Jwt.getPhone(token)}
                        </p>
                    }
                >
                    Phone
                </Label>
                <Label
                    description={
                        <p>
                            {(profile && profile.email) || Jwt.getEmail(token)}
                        </p>
                    }
                >
                    Email
                </Label>
                {get(state, 'credit.credit.credit', 0) &&
                state.product.product.id ===
                    process.env.REACT_APP_SHORT_HOMECOVER_PRODUCT_ID ? (
                    <Label
                        description={
                            <p>
                                {get(state, 'credit.credit.credit', 0) -
                                    selectedCredits}
                            </p>
                        }
                    >
                        Number of free night credits available
                    </Label>
                ) : (
                    <></>
                )}

                <small style={{ fontWeight: 'bold' }}>
                    Note: By purchasing you agree to your policy documents and
                    other policy communications being sent to your listed email
                    address
                </small>
                <br />
                <br />
                <Link
                    href={`https://id.${process.env.REACT_APP_DOMAIN_NAME}/update-details?redirect_idx=11`}
                    sx={{ variant: 'links.buttonOutline' }}
                >
                    Update Profile
                </Link>
            </div>
            <Divider />
            {get(profile, 'address.formatted') && (
                <>
                    <Label
                        description={<p>{get(profile, 'address.formatted')}</p>}
                    >
                        Address
                    </Label>

                    <Link
                        href={'/payment-method'}
                        sx={{ variant: 'links.button' }}
                    >
                        Next
                    </Link>
                </>
            )}

            {existy(get(profile, 'address.formatted')) || (
                <>
                    <AddressForm
                        initialValues={{
                            address: {
                                formatted: '',
                                isManualAddress: true,
                                suiteAddress: '',
                                additionalAddress: '',
                                streetAddress: '',
                                town: '',
                                city: '',
                                region: '',
                                postcode: '',
                                country: 'AU',
                                placeId: '',
                                lat: '',
                                lng: ''
                            },
                            termsAndConditions: '',
                            insuranceDeclined: '',
                            criminalOffence: '',
                            previousAddress: ''
                        }}
                        getSelectedCredits={getSelectedCredits}
                        onSubmit={async (values) => {
                            await updateAddress(
                                values,
                                state.product.product.id
                            )
                            history.push('/payment-method')
                        }}
                    />
                </>
            )}
        </CheckoutLayout>
    )
}

const mapStateToProps = (state) => ({
    ...state.profile,
    state: state
})

const mapDispatchToProps = {
    updateAddress,
    getCredit
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
