import * as mutations from '../graphql/mutations'
import { API, graphqlOperation } from 'aws-amplify'
const recalculateQoute = async (quote, credit) => {
    let input = { ...quote, productId: quote.product.id }
    delete input.userId
    delete input.product
    delete input.premium
    delete input.unit
    delete input.ttl
    delete input.createdAt
    input.data = JSON.stringify({
        ...input.data,
        credit
    })

    try {
        await API.graphql(graphqlOperation(mutations.quote, { input }))
    } catch (e) {
        throw e
    }
}

export default recalculateQoute
