import * as actions from '../actions'

const INITIAL_STATE = {
    data: {}
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.NEW_SESSION:
            return { ...INITIAL_STATE, ...action.data }

        case actions.ADD_DATA:
            return { ...state, data: { ...state.data, ...action.data } }

        default:
            return state
    }
}
