import * as actions from '../actions'

const INITIAL_STATE = {
    loading: false,
    quote: null,
    error: null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.NEW_SESSION:
            return { ...INITIAL_STATE }

        case actions.GET_QUOTE_REQUEST:
            return { ...state, loading: true }

        case actions.GET_QUOTE_SUCCESS:
            const { data, ...quote } = action.item

            return {
                ...state,
                loading: false,
                quote: { ...quote, data: JSON.parse(data) },
                error: null
            }

        case actions.GET_QUOTE_FAILED:
            return { ...state, loading: false, error: action.error }

        default:
            return state
    }
}
