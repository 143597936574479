import React from 'react'
import { connect } from 'react-redux'
import { Radio, Select } from '@sharecover-co/ui'
import { getQuote } from '../../../actions/quote'
import { reduxForUpdateQuote } from '../../../actions/quote'
import creditChoices from '../../../utils/creditChoices'
import get from 'lodash.get'
import recalculateQoute from '../../../utils/recalculateQuote'
const AddtionalQuestions = (props) => {
    let condition =
        get(props, 'product.product.id', null) ===
            process.env.REACT_APP_LONG_HOMECOVER_PRODUCT_ID ||
        get(props, 'product.product.id', null) ===
            process.env.REACT_APP_SHORT_HOMECOVER_PRODUCT_ID

    return condition ? (
        <>
            {' '}
            <Radio
                name="termsAndConditions"
                label="I have read and understand the terms, conditions, limits and exclusions of the policy outlined in the PDS"
                required
                choices={{
                    [true]: 'Yes',
                    [false]: 'No'
                }}
            />
            <Radio
                name="insuranceDeclined"
                label="Have you ever had home building or contents insurance declined, refused to be renewed, cancelled or had an insurance claim refused at any time in the past five years?"
                required
                choices={{
                    [true]: 'Yes',
                    [false]: 'No'
                }}
            />
            <Radio
                name="criminalOffence"
                label="Have you ever been convicted of a criminal offence involving fraud or dishonesty; theft or robbery; arson; damage or threatened property damage; injury or threatened injury to any persons; or drugs in the past five years?"
                required
                choices={{
                    [true]: 'Yes',
                    [false]: 'No'
                }}
            />
            {get(props, 'product.product.id', null) ===
                process.env.REACT_APP_SHORT_HOMECOVER_PRODUCT_ID && (
                <Select
                    name="freeNights"
                    id="freeNights"
                    label="How many free night credits do you wish to use for this booking?
            "
                    choices={creditChoices(
                        get(props, 'credit.credit.credit', 0),
                        props.quote.quote.data.startDate,
                        props.quote.quote.data.endDate
                    )}
                    onChange={async (e) => {
                        props.getSelectedCredits(e.target.value)
                        await props.reduxForUpdateQuote(
                            props.quote.quote.id,
                            props.quote.quote.accessKey
                        )
                        await recalculateQoute(
                            props.quote.quote,
                            e.target.value
                        )
                        await props.getQuote(
                            props.quote.quote.id,
                            props.quote.quote.accessKey
                        )
                    }}
                />
            )}
        </>
    ) : (
        <></>
    )
}

const mapStateToProps = (state) => ({
    ...state
})

const mapDispatchToProps = {
    getQuote,
    reduxForUpdateQuote
}

export default connect(mapStateToProps, mapDispatchToProps)(AddtionalQuestions)
