import * as actions from '../actions'

const INITIAL_STATE = {
    loading: false,
    policy: null,
    error: null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.NEW_SESSION:
            return { ...INITIAL_STATE }

        case actions.ADD_POLICY_REQUEST:
            return { ...state, loading: true }

        case actions.ADD_POLICY_SUCCESS:
            return {
                policy: action.item,
                loading: false,
                error: null
            }

        case actions.ADD_POLICY_FAILED:
            return { ...state, loading: false, error: action.error }

        default:
            return state
    }
}
