import React, { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import LoadingLayout from '../layouts/LoadingLayout'
import { goTo } from '../../lib/navigation'

const IsAuthenticated = (props) => {
    const [status, setStatus] = useState({
        pending: true,
        authenticated: false
    })

    // Check for profile in storage and re-hydrate if it exists
    useEffect(() => {
        Auth.currentCredentials()
            .then(({ authenticated }) => {
                setStatus({ authenticated, pending: false })
            })
            .catch((error) => {
                console.log('error:', error)
                setStatus({ pending: false })
            })
    }, [])

    if (status.pending) return <LoadingLayout />

    return status.authenticated
        ? props.children
        : goTo(`${process.env.REACT_APP_AUTH_URL}/refresh`)
}

export default IsAuthenticated
