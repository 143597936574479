// We need to simulate a click so referrer is accurate on id.sharecover.tld
// we use that referrer to redirect the user back after authn
export function goTo(url) {
    var a = document.createElement('a')
    if (a.click) {
        // HTML5 browsers and IE support click() on <a>, early FF does not.
        a.setAttribute('href', url)
        a.setAttribute('referrerpolicy', 'no-referrer-when-downgrade')
        // a.setAttribute('referrerpolicy', 'unsafe-url')
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
    } else {
        // Early FF can, however, use this usual method
        // where IE cannot with secure links.
        window.location = url
    }
}
