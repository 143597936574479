import { API, graphqlOperation } from 'aws-amplify'
import * as actions from './index'
import * as queries from '../graphql/queries'

// getQuote
const getCreditRequest = (email) => ({
    type: actions.GET_CREDIT_REQUEST,
    email
})
const getCreditSuccess = (item) => ({
    type: actions.GET_CREDIT_SUCCESS,
    item
})
const getCreditFailed = (error) => ({
    type: actions.GET_CREDIT_FAILED,
    error
})

export const getCredit = (email) => async (dispatch) => {
    dispatch(getCreditRequest(email))

    try {
        const response = await API.graphql(
            graphqlOperation(queries.getCredit, {
                email: email.toLowerCase()
            })
        )
        if (!response.data.getCredit) {
            dispatch(
                getCreditSuccess({
                    credit: null,
                    email,
                    id: null,
                    initialCredit: null
                })
            )
        }
        dispatch(getCreditSuccess(response.data.getCredit))
    } catch (e) {
        dispatch(getCreditFailed(e.message))
        throw e
    }
}
