import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Checkbox, Submit, Heading, Text, Link } from '@sharecover-co/ui'
import * as Yup from 'yup'
import { Form } from 'formik'
import FormManager from './FormManager'

const schema = Yup.object().shape({
    agree: Yup.string()
        .required('Required')
        .oneOf(['y'], 'You must agree to the terms.')
})

const ConfirmForm = (props) => (
    <FormManager
        validationSchema={schema}
        initialValues={{ agree: '' }}
        {...props}
    >
        {({ status: { error } }) => (
            <Form>
                {error && <Alert>{error}</Alert>}
                <br />
                <Heading sx={{ fontSize: '18px' }}>Do you agree?</Heading>
                <br />
                <Text sx={{ fontSize: '12px', fontWeight: 'bold' }}>
                    By proceeding, you confirm that the PDS has been made
                    available to you. The PDS details the cover, limitations and
                    exclusions that apply to the policy together with the
                    Certificate of Insurance that will be provided to you. You
                    should read the{' '}
                    <Link
                        href={`https://${process.env.REACT_APP_DOMAIN_NAME}/product-disclosures`}
                        target="_blank"
                    >
                        PDS{' '}
                    </Link>
                    and Target Market Determination{' '}
                    <Link
                        href={`https://${process.env.REACT_APP_DOMAIN_NAME}/product-disclosures`}
                        target="_blank"
                    >
                        (TMD){' '}
                    </Link>
                    carefully to help you make an informed decision about the
                    policy and ensure it provides the cover you want.
                </Text>
                <Checkbox
                    name="agree"
                    // label="Do you agree?"
                    choices={{
                        y: (
                            <Text>
                                Yes, I agree to the terms and conditions stated
                                in the Product Disclosure Statement{' '}
                                <Link
                                    href={`https://${process.env.REACT_APP_DOMAIN_NAME}/product-disclosures`}
                                    target="_blank"
                                >
                                    (PDS){' '}
                                </Link>
                                .
                            </Text>
                        )
                    }}
                    required
                />
                <Submit label="Purchase" />
            </Form>
        )}
    </FormManager>
)

ConfirmForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
}

export default ConfirmForm
