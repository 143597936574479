import * as actions from '../actions'

const INITIAL_STATE = {
    loading: false,
    product: null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.NEW_SESSION:
            return { ...INITIAL_STATE }

        case actions.GET_QUOTE_SUCCESS:
            return { ...INITIAL_STATE, product: action.item.product }

        default:
            return state
    }
}
