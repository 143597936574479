import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import qs from 'qs'
import { newSession, addData } from '../../actions/session'
import { getQuote } from '../../actions/quote'
import CheckoutLayout from '../layouts/CheckoutLayout'

const NewQuote = ({
    newSession,
    getQuote,
    addData,
    match: {
        params: { id: quoteId }
    },
    history: {
        location: { search }
    }
}) => {
    const [ready, setIsReady] = useState(false)
    const args = qs.parse(search, { ignoreQueryPrefix: true })
    const accessKey = args.accessKey || null

    useEffect(() => {
        const start = async () => {
            await getQuote(quoteId, accessKey)
            addData({ quoteId, accessKey })
            setIsReady(true)
        }

        newSession()
        start()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quoteId])

    if (ready) {
        return <Redirect to="/account" />
    }

    // @todo: show error if unable to find quote

    return <CheckoutLayout title="Checkout" progress={0} loading />
}

const mapDispatchToProps = {
    newSession,
    getQuote,
    addData
}

export default connect(null, mapDispatchToProps)(NewQuote)
