import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Alert, Select, Submit } from '@sharecover-co/ui'
import * as Yup from 'yup'
import { Form } from 'formik'
import FormManager from './FormManager'
import { API, graphqlOperation } from 'aws-amplify'
import * as mutations from '../../graphql/mutations'
import formatCurrency from '../../lib/formatCurrency'
import schedules from '../base/Schedules'
import pick from 'lodash.pick'

const schema = Yup.object().shape({
    paymentSchedule: Yup.string().required('Required')
})

const PaymentForm = ({ quote, ...rest }) => {
    const [schedule, setSchedule] = useState()
    useEffect(() => {
        const fetchData = async () => {
            const {
                data: {
                    schedule: { schedule }
                }
            } = await API.graphql(
                graphqlOperation(mutations.schedule, {
                    input: {
                        paymentSchedule: quote.product.paymentSchedules[0],
                        price: { ...quote.premium },
                        startDate: quote.startDate,
                        numberOfMonthes: quote.data.months === 6 ? '6' : null
                    }
                })
            )
            setSchedule(schedule)
        }

        fetchData()
    }, [quote])

    return (
        <FormManager
            useSession
            validationSchema={schema}
            initialValues={{ paymentSchedule: '' }}
            {...rest}
        >
            {({ status: { error }, values: { paymentSchedule } }) => (
                <Form>
                    {error && <Alert>{error}</Alert>}
                    <Select
                        id="paymentSchedule"
                        name="paymentSchedule"
                        label="Payment Schedule"
                        required
                        choices={pick(
                            schedules,
                            quote.product.paymentSchedules
                        )}
                    />
                    {paymentSchedule === 'MONTHLY' && (
                        <>
                            {schedule ? (
                                <p>
                                    You'll pay {schedule.length - 1} monthly
                                    installments of{' '}
                                    {formatCurrency(schedule[0].total)} and one
                                    of{' '}
                                    {formatCurrency(
                                        schedule[schedule.length - 1].total
                                    )}{' '}
                                    as your final monthly installment.
                                </p>
                            ) : (
                                <p>Loading...</p>
                            )}
                        </>
                    )}
                    <Submit label="Next" />
                </Form>
            )}
        </FormManager>
    )
}

PaymentForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
}

export default PaymentForm
