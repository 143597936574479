import * as actions from '../actions'

const INITIAL_STATE = {
    loading: false,
    credit: null,
    error: null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.NEW_SESSION:
            return { ...INITIAL_STATE }

        case actions.GET_CREDIT_REQUEST:
            return { ...state, loading: true }

        case actions.GET_CREDIT_SUCCESS:
            const credit = action.item
            return {
                ...state,
                loading: false,
                credit: credit,
                error: null
            }

        case actions.GET_CREDIT_FAILED:
            return { ...state, loading: false, error: action.error }

        default:
            return state
    }
}
