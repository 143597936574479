import React from 'react'
import { connect } from 'react-redux'
import {
    Flex,
    Box,
    Heading,
    Text,
    Tag,
    parseFields,
    Loading
} from '@sharecover-co/ui'
import get from 'lodash.get'
import formatCurrency from '../../lib/formatCurrency'
import schedules from './Schedules'

const checkoutFields = [
    {
        format: 'STRING',
        name: 'Payment Method',
        path: 'paymentMethodId'
    },
    {
        format: 'MAP',
        name: 'Payment Schedule',
        path: 'paymentSchedule',
        config: JSON.stringify(schedules)
    }
]

const makeDataTable = (data, fields) => {
    return parseFields(data, fields).map(([key, val]) => (
        <Flex
            key={key}
            sx={{
                borderBottom: '2px solid',
                borderBottomColor: 'lightGrey',
                mt: 1,
                pb: 1,
                ':last-child': {
                    borderBottom: 'none',
                    pb: 0
                },
                '> strong': {
                    flex: 1
                },
                '> span': {
                    textAlign: 'right',
                    whiteSpace: 'pre-line'
                }
            }}
        >
            <strong>{key}</strong>
            <span>{val}</span>
        </Flex>
    ))
}

const Pricing = ({ session, quote, loading, policy, product }) => {
    return loading ? (
        <Loading />
    ) : (
        <Box variant="boxed">
            {quote && !policy && (
                <>
                    <Tag>Quote {quote.id}</Tag>
                    {quote.premium && (
                        <Box sx={{ mb: 4 }}>
                            <Heading as="h4">
                                {formatCurrency(quote.premium.total)} total
                                (inc. taxes)
                            </Heading>
                            <Text sx={{ mb: 2 }}>Premium</Text>
                        </Box>
                    )}
                    {/* {quote.unit && (
                    <Box sx={{ mb: 4 }}>
                        <Heading as="h4">
                            {formatCurrency(quote.unit.total)} (inc. taxes)
                        </Heading>
                        <Text sx={{ mb: 2 }}>Unit Cost</Text>
                    </Box>
                )} */}
                </>
            )}
            {policy && (
                <Box sx={{ mb: 4 }}>
                    <Tag>Policy {policy.id}</Tag>
                    <Heading as="h3">{product.name}</Heading>

                    {policy.premium && (
                        <Box sx={{ mb: 4 }}>
                            <Heading as="h4">
                                {formatCurrency(policy.premium.total)} total
                                (inc. taxes)
                            </Heading>
                            <Text sx={{ mb: 2 }}>Premium</Text>
                        </Box>
                    )}
                    {/* {policy.unit && (
                    <Box sx={{ mb: 4 }}>
                        <Heading as="h4">
                            {formatCurrency(policy.unit.total)} (inc. taxes)
                        </Heading>
                        <Text sx={{ mb: 2 }}>Unit Cost</Text>
                    </Box>
                )} */}
                </Box>
            )}
            {makeDataTable(quote.data, product.quoteFlow.fields)}
            {makeDataTable(session, checkoutFields)}
        </Box>
    )
}

const mapStateToProps = (state) => ({
    session: get(state, 'session.data', {}),
    quote: get(state, 'quote.quote'),
    loading: get(state, 'quote.loading'),
    policy: get(state, 'policy.policy'),
    product: get(state, 'product.product')
})

export default connect(mapStateToProps)(Pricing)
